import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { CustomNavbar } from '../../../components/NavBar'
import { Card, Container, Form, ListGroup, Row, Spinner } from 'react-bootstrap'
import { Field } from '../../../components/Field'
import { useForm, useWatch } from 'react-hook-form'
import Calendar from 'react-calendar'
import {
  weekenddaysHoursOptions,
  weekdaysHoursOptions,
  WeekDaysHourOptions,
  WeekendsHourOptions,
  BookedClass,
  // TrackOptionsType,
} from '../../../domain/models/classes.models'
import { englishFormatDate } from '../../../utils/date'
import { CancelClass } from '../../CancelClass/CancelClass'
import { ColWithMarginTop } from '../../../components/StyledComponents'
import { ClassesContext } from '../../../components/context/Classes.context'

const morningHourOptions = [
  '',
  'furniture',
  'homeAppliances',
  'fixes',
  'insurances',
  'taxes',
  'other',
]
const morningHourOptionsType = [...morningHourOptions] as const
export type MorningHour = typeof morningHourOptionsType

export type BookedClassForm = {
  date: Date
  track: 'little' | 'medium' | 'big'
  hour: WeekendsHourOptions | WeekDaysHourOptions
}

export const ClassBooked: React.FC = () => {
  const [dateSelected, onDateSelected] = React.useState<Date | null>(new Date())
  const [isCancelling, setIsCancelling] = React.useState(false)
  const [selectedClass, setSelectedClass] = React.useState<BookedClass>(null)
  const [classesShowed, setclassesShowed] = React.useState<BookedClass[]>([])

  const {
    getClassesBooked,
    classes,
    onExecuteAvailableHorses,
    availableHorses,
  } = React.useContext(ClassesContext)

  React.useEffect(() => {
    let currentDate = new Date()

    // Restar 1 año a la fecha actual
    currentDate.setFullYear(currentDate.getFullYear() - 1)

    getClassesBooked(currentDate)
    if (!availableHorses) {
      onExecuteAvailableHorses()
    }
  }, [])

  /*     React.useEffect(() => {
      if (!!classes) {
        setIsLoading(false)
      }
    }, [classes]) */

  const isWeekday = () => {
    const currentDayOfWeek = dateSelected.getDay()

    return currentDayOfWeek < 6 && currentDayOfWeek !== 0
  }

  const getHours = () => {
    if (isWeekday) {
      return Object.keys(weekdaysHoursOptions)
    }
    return Object.keys(weekenddaysHoursOptions)
  }

  const getHourText = (hour) => {
    if (isWeekday) {
      return weekdaysHoursOptions[hour]
    }
    return weekenddaysHoursOptions[hour]
  }

  const { register, control, setValue } = useForm<BookedClassForm>({
    mode: 'all',
    defaultValues: { hour: getHours()[0] as any },
  })

  const watchedHour = useWatch({
    control,
    name: 'hour',
  })

  const getByDate = (): BookedClass[] => {
    const classesByDate =
      classes !== undefined && classes !== ('' as any)
        ? classes?.filter(
            (classResponse) =>
              englishFormatDate(classResponse?.date) ===
              englishFormatDate(dateSelected),
          )
        : []
    const classesByHour = classesByDate?.filter(
      (classResponse) => classResponse?.hour === watchedHour,
    )
    return classesByHour
  }

  React.useEffect(() => {
    setValue('hour', getHours()[0] as any)
  }, [dateSelected])

  React.useEffect(() => {
    setclassesShowed(getByDate())
  }, [dateSelected, watchedHour])

  React.useEffect(() => {
    console.log('entra', dateSelected, watchedHour)
    setclassesShowed(getByDate())
  }, [classes])

  if (
    /*     state === 'pending' ||
    state === 'waiting' || */
    classes === undefined ||
    classes === ('' as any)
  ) {
    return <Spinner />
  }

  const getByTrack = (classesToFilter: BookedClass[], track): BookedClass[] => {
    const classesByTrack = classesToFilter?.filter(
      (classResponse) => classResponse?.track === track,
    )

    return classesByTrack
  }

  /*   const classesFilter = getByDate()
   */ return (
    <div>
      <CustomNavbar />
      {isCancelling && (
        <CancelClass
          onFinishAction={() => {
            setIsCancelling(false)
            setSelectedClass(null)
          }}
          cancelAction={isCancelling}
          selectedClassByTeacher={selectedClass}
        />
      )}
      <form onSubmit={null} className="mb-3">
        <div className="container mt-5">
          <Container className="mb-4">
            <Row>
              <ColWithMarginTop>
                <h3>
                  Clases reservadas para el día {dateSelected.getDate()} en el
                  horario {watchedHour}
                </h3>
                <p>Selecciona el horario</p>
                <Field control={control} name="hour" label="">
                  <Form.Select {...register('hour')}>
                    {getHours().map((hour) => (
                      <option value={hour}>{getHourText(hour)}</option>
                    ))}
                  </Form.Select>
                </Field>
              </ColWithMarginTop>
              <ColWithMarginTop>
                <Calendar
                  activeStartDate={null}
                  defaultActiveStartDate={null}
                  onChange={(e) => {
                    const prueba = e as Date
                    onDateSelected(prueba)
                  }}
                  value={dateSelected}
                />
              </ColWithMarginTop>
            </Row>
          </Container>

          <Row className="d-flex justify-content-center">
            <ColWithMarginTop
              className="justify-content-center"
              style={{ textAlign: 'center' }}
            >
              <Row className="d-flex justify-content-center">
                <h4>Pista Mediana</h4>
                <Card style={{ width: '18rem' }}>
                  <Card.Header style={{ fontWeight: '600' }}>
                    {getByTrack(classesShowed, 'Mediana')?.length} Alumnos
                  </Card.Header>
                  <ListGroup variant="flush">
                    {getByTrack(classesShowed, 'Mediana')?.map((alumno) => (
                      <ListGroup.Item>
                        <p>Nombre: {alumno.name}</p>
                        <p>Teléfono: {alumno.phone}</p>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              </Row>
            </ColWithMarginTop>
            <ColWithMarginTop
              className="justify-content-center"
              style={{ textAlign: 'center' }}
            >
              <Row className="d-flex justify-content-center">
                <h4>Pista Grande</h4>
                <Card style={{ width: '18rem' }}>
                  <Card.Header style={{ fontWeight: '600' }}>
                    {getByTrack(classesShowed, 'Grande')?.length} Alumnos
                  </Card.Header>
                  <ListGroup variant="flush">
                    {getByTrack(classesShowed, 'Grande')?.map((alumno) => (
                      <ListGroup.Item
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          await setSelectedClass(alumno)
                          await setIsCancelling(true)
                        }}
                      >
                        <p>Nombre: {alumno.name}</p>
                        <p>Teléfono: {alumno.phone}</p>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card>
              </Row>
            </ColWithMarginTop>
          </Row>
        </div>
      </form>
    </div>
  )
}
