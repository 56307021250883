export const weekdays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes']
export const weekenddays = ['Sábado', 'Domingo']

export const weekdaysHoursOptions = {
  first: '16:15',
  second: '17:15',
  third: '18:15',
}

export const weekenddaysHoursOptions = {
  first: '09:15',
  second: '10:20',
  third: '11:30',
  fourth: '12:45',
}

export const weekdaysHoursNumber = {
  first: 16,
  second: 17,
  third: 18,
}

export const weekenddaysHoursNumber = {
  first: 9,
  second: 10,
  third: 11,
  fourth: 12,
}

export type WeekDaysHourOptions = keyof typeof weekdaysHoursOptions
export type WeekendsHourOptions = keyof typeof weekenddaysHoursOptions

/* export const weekdaysHoursOptions = ['16-17', '17-18', '18-19', '19-20']
export const weekenddaysHoursOptions = ['9-10', '10-11', '11-12', '12-13']

const weekDaysHourOptionsType = ['16-17', '17-18', '18-19', '19-20'] as const
export type WeekDaysHourOptions = typeof weekDaysHourOptionsType
const weekdaysHoursOptionsType = ['9-10', '10-11', '11-12', '12-13'] as const
export type WeekendsHourOptions = typeof weekdaysHoursOptionsType */

export const trackOptions = ['Pequeña', 'Mediana', 'Grande']
const trackOptionsType = ['Pequeña', 'Mediana', 'Grande'] as const
export type TrackOptionsType = typeof trackOptionsType

export type BookedClass = {
  date: Date
  track: TrackOptionsType
  hour: WeekendsHourOptions | WeekDaysHourOptions
  name: string
  surname: string
  phone: string
  id: string
  level: 'Trote' | 'Galope' | 'Salto'
}

export const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export type HorsesAvailable = {
  track: TrackOptionsType
  hour: WeekendsHourOptions | WeekDaysHourOptions
  maxJump: number
  totalMax: number
  maxGalop: number
  id: string
}
