import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getBaseUrl, getExtension } from './api.utils'

export interface ApiClient {
  get<P>(path: string, params?: any, options?: AxiosRequestConfig): Promise<P>
  post<P>(path: string, data?: any, options?: AxiosRequestConfig): Promise<P>
}

export class AxiosApi implements ApiClient {
  private instance: AxiosInstance
  private extension: string

  constructor(baseUrl, extension) {
    this.extension = extension
    this.instance = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
      headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
    })

    this.instance.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
  }

  get axiosInstance(): AxiosInstance {
    return this.instance
  }

  get<P>(path: string, params?: any, options?: AxiosRequestConfig): Promise<P> {
    return new Promise<P>((resolve, reject) => {
      this.instance
        .get(`${path}.${this.extension}`, { ...options, params })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error)
        })
    })
  }

  post<P>(
    path: string,
    data?: Object,
    options?: AxiosRequestConfig,
  ): Promise<P> {
    return new Promise<P>((resolve, reject) => {
      this.instance
        .request({
          url: `${path}.${this.extension}`,
          method: `${this.extension === 'json' ? 'GET' : 'POST'}`,
          data,
          ...options,
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export const dataToFormData = (data: Object) => {
  const formData = new FormData()

  Object.keys(data).forEach((key) => formData.append(key, data[key]))

  return formData
}
export const dataToURLSearchParams = (data: Record<string, any>) => {
  const urlSearchParams = new URLSearchParams()

  Object.keys(data).forEach((key) => {
    const value = data[key]
    if (value !== undefined && value !== null) {
      /*     const sanitizedKey = key.replace(/[^a-zA-Z0-9]/g, '') // Eliminar caracteres especiales del key
      const sanitizedValue = String(value).replace(/[^a-zA-Z0-9]/g, '') // Eliminar caracteres especiales del value */
      urlSearchParams.append(key, value)
    }
  })

  return urlSearchParams
}

const axiosApi = new AxiosApi(getBaseUrl(), getExtension()) as ApiClient

export default axiosApi
