// src/App.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
/* import { CustomNavbar } from '../../components/NavBar'
 */ import {
  /*  Card, Col, Form, Row, */
  Form,
  Spinner,
} from 'react-bootstrap'
/* import { Field } from '../../components/Field'
import { useLocation } from 'react-router' */
import 'react-calendar/dist/Calendar.css'
import { ActionSheet } from '../../components/Modal'
import { BookedClass } from '../../domain/models/classes.models'
import { useService } from '../../hooks/useService'
import { getClassesByPhone } from '../../domain/services/classes.service'
import { /* englishFormatDate, */ formatDate } from '../../utils/date'
import { MainButton } from '../views.styles'
import { Field } from '../../components/Field'
import { useForm, useWatch } from 'react-hook-form'
import { validatePhoneNumber } from '../../utils/general'
import {
  getHourNumber,
  getHourText,
} from '../BookYourClass/BookYourClass.utils'

export const CancelClassPublicModal: React.FC<{
  onClose: () => void
  onSelectToCancel: (bookedToCancel: BookedClass) => void
}> = ({ onClose, onSelectToCancel }) => {
  // const location = useLocation()
  const {
    register,
    // trigger,
    control,
    getValues,
  } = useForm<{ phone: string; selectedClass: string }>({
    mode: 'all',
  })

  const watchedPhone = useWatch({
    control,
    name: 'phone',
  })

  const watchedClass = useWatch({
    control,
    name: 'selectedClass',
  })

  const {
    response: classesWithTelephoneSelected,
    state,
    onExecute,
  } = useService<BookedClass[]>({
    callBack: () =>
      getClassesByPhone({ phone: watchedPhone, dateFrom: new Date() }),
    executeOnStart: false,
  })

  const getClass = (id: string) =>
    classesWithTelephoneSelected.find((classes) => classes.id === id)

  const getDisabled = (classBooked: BookedClass) => {
    const now = new Date()
    function checkTimeDifferenceForTomorrow(targetHour) {
      // Fecha y hora actual

      // Crear una fecha objetivo para mañana a la hora especificada
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1) // Establecer para el día siguiente
      tomorrow.setHours(targetHour, 0, 0, 0) // Establecer la hora objetivo (por ejemplo, 9 AM)

      // Calcular la diferencia en milisegundos
      const timeDiff = tomorrow.getTime() - now.getTime()

      // Convertir la diferencia a horas
      const hoursDiff = timeDiff / (1000 * 60 * 60)

      if (hoursDiff > 12) {
        return false
      } else if (hoursDiff >= 0 && hoursDiff <= 12) {
        return true
      } else {
        return true
      }
    }

    function checkDate() {
      // Obtener la fecha actual (hoy) sin la parte de la hora
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      // Obtener la fecha objetivo sin la parte de la hora
      const date = new Date(classBooked.date)
      date.setHours(0, 0, 0, 0)

      // Calcular la diferencia de tiempo entre las dos fechas en milisegundos
      const timeDiff = date.getTime() - today.getTime()

      // Convertir la diferencia de tiempo a días
      const dayDiff = timeDiff / (1000 * 60 * 60 * 24)

      if (dayDiff === 0) {
        return 'Hoy'
      } else if (dayDiff === 1) {
        return 'Mañana'
      } else if (dayDiff > 1) {
        return `Faltan ${Math.round(dayDiff)} días para esa fecha`
      } else {
        return 'La fecha ya pasó'
      }
    }
    if (checkDate() === 'Hoy') {
      return (
        now.getHours() - getHourNumber(classBooked.hour, classBooked.date) < 12
      )
    }
    if (checkDate() === 'Mañana') {
      return checkTimeDifferenceForTomorrow(
        getHourNumber(classBooked.hour, classBooked.date),
      )
    }
    return false
  }
  return (
    <div>
      <ActionSheet isOpen onClose={onClose} title="Cancelar clase">
        <form onSubmit={null} className="mb-3">
          <Field control={control} label="Número de teléfono" name="phone">
            <Form.Control
              placeholder="666222888"
              {...register('phone', {
                required: 'Este campo es obligatorio',
                validate: validatePhoneNumber,
              })}
            />
          </Field>
          <MainButton
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onExecute({ phone: watchedPhone, dateFrom: new Date() })
            }}
            className="mt-3 mb-6"
          >
            {state === 'pending' ? (
              <Spinner />
            ) : (
              'Ver clases asociadas al teléfono'
            )}
          </MainButton>
          {classesWithTelephoneSelected && (
            <>
              <p className="mt-3 mb-6">Selecciona que clase quieres cancelar</p>
              <p>Recuerda que solo podrás cancelar clases 12 horas antes</p>

              <Field control={control} name="selectedClass" label="">
                <Form.Select {...register('selectedClass')}>
                  {classesWithTelephoneSelected.map((type) => (
                    <option value={type.id} disabled={getDisabled(type)}>
                      <span>{`Día ${formatDate(
                        type.date,
                      )},  Hora: ${getHourText(
                        type.hour,
                        type.date,
                      )}, Alumno: ${type.name}`}</span>
                    </option>
                  ))}
                </Form.Select>
              </Field>
              <MainButton
                className="mt-3 mb-6"
                disabled={watchedClass?.length === 0}
                onClick={() =>
                  onSelectToCancel(getClass(getValues().selectedClass))
                }
              >
                Cancelar clase
              </MainButton>
            </>
          )}
        </form>
      </ActionSheet>
    </div>
  )
}
